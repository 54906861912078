<template>
  <v-row no-gutters style="margin-top: 20px">
    <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown">
      <user-profile-drawer />
    </v-col>
    <v-col
      cols="12"
      xl="9"
      lg="9"
      md="9"
      sm="12"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 20px'"
    >
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
    </v-col>
  </v-row>
</template>

<script>
import userProfileDrawer from "./userProfileDrawer.vue";
export default {
  components: {
    userProfileDrawer,
  },
};
</script>

<style>
</style>