<template>
  <div style="min-height: 100svh">
    <div class="myBonusesCard">
      <p style="margin-bottom: 12px">
        {{ "avaibels_bonuses_label" | localize }}
      </p>
      <span
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: #fed500;
        "
        >{{ balance.toFixed(2) }}
        <img
          src="@/assets/img/iconsSvg/bonusIcon.svg"
          width="18px"
          height="19px"
          alt="bonus"
        />
      </span>
    </div>
    <div class="cardBackground">
      <v-row no-gutters align="center">
        <v-col cols="2" class="px-0 py-0">
          <img src="@/assets/img/iconsSvg/myProfileIcon.svg" alt="my profile" />
        </v-col>
        <v-col cols="10" class="py-0" style="padding-left: 12px">
          <p style="margin-bottom: 0px">
            {{ loggedUserPTrans.first_name }} {{ loggedUserPTrans.last_name }}
          </p>
          <span style="font-size: 14px; color: #4b5262">{{
            loggedUserPTrans.email
          }}</span>
        </v-col>
      </v-row>
    </div>
    <div class="cardBackground">
      <router-link
        v-for="item in profileItemList"
        :key="item.id"
        :to="item.path"
      >
        <div
          class="listItem"
          :class="$route.path == item.path ? 'activeListItem' : ''"
        >
          <div :class="item.icon" style="margin-right: 12px" />
          {{ item.title | localize }}
        </div>
      </router-link>

      <div class="sectionLine" style="margin: 8px 0px"></div>
      <div class="listItem" @click="logout">
        <div class="logoutIcon" style="margin-right: 12px" />
        {{ "logout_label" | localize }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "../../../requests/User/profileService";
export default {
  data: () => ({
    activeProfileItem: "my_ticket",
    profileItemList: [
      {
        id: 1,
        title: "my_ticket_label",
        icon: "myTicketIcon",
        link: "my_ticket",
        path: "/profile/my-ticket",
      },
      {
        id: 2,
        title: "bonuses_label",
        icon: "profileBonusIcon",
        link: "bonuses",
        path: "/profile/bonuses",
      },
      {
        id: 3,
        title: "personal_data_label",
        icon: "settingIcon",
        link: "setting",
        path: "/profile/setting",
      },
    ],
    balance: 0,
  }),
  mounted() {
    this.activeProfileItem = this.$route.params.page;
    this.getBalance();
  },
  methods: {
    async getBalance() {
      await profileService.getBalance().then((res) => {
        if (res.status == "Success") {
          this.balance = res.data.balance;
        }
      });
    },
    logout() {
      this.$store.commit("clearUserLogged");
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
  watch: {
    activeProfileItem: {
      handler() {
        this.$emit("changeActiveItem", this.activeProfileItem);
      },
    },
    "$route.path": {
      handler() {
        this.activeProfileItem = this.$route.params.page;
      },
    },
  },
};
</script>

<style scoped>
.myBonusesCard {
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(51, 72, 135, 0) 0%, #172b69 100%),
    #144fa9;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  padding: 16px;
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  height: max-content;
}
.cardBackground {
  border-radius: 10px;
  background: #fafafa;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
}
.listItem {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  color: #1b1b1b;
}
.listItem:hover {
  color: #144fa9;
  background: #e7edf6;
}
.listItem:hover .myTicketIcon {
  background: #144fa9;
}
.listItem:hover .settingIcon {
  background: #144fa9;
}
.listItem:hover .profileBonusIcon {
  background: #144fa9;
}
.listItem:hover .logoutIcon {
  background: #144fa9;
}
.activeListItem {
  color: #144fa9;
  background: #e7edf6;
}
.activeListItem:hover .myTicketIcon {
  background: #144fa9;
}
.activeListItem:hover .settingIcon {
  background: #144fa9;
}
.activeListItem:hover .profileBonusIcon {
  background: #144fa9;
}
.activeListItem:hover .logoutIcon {
  background: #144fa9;
}
.activeListItem .myTicketIcon {
  background: #144fa9;
}
.activeListItem .settingIcon {
  background: #144fa9;
}
.activeListItem .profileBonusIcon {
  background: #144fa9;
}
.activeListItem .logoutIcon {
  background: #144fa9;
}
</style>