<template>
  <v-main>
    <v-container
      :style="$vuetify.breakpoint.smAndDown ? 'padding: 20px!important' : ''"
    >
      <user-profile-component />
    </v-container>
  </v-main>
</template>

<script>
import userProfileComponent from "../../components/User/userProfile/userProfileComponent.vue";
export default {
  components: { userProfileComponent },
};
</script>

<style>
</style>